.about{
    background-color: #474B4F;
    padding: 2em 10vw 2em 10vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.texts{
    width: 100%;
    padding: 0em 5em 0em 5em;
}
.about img{
    width: 20em;
    object-fit:scale-down;
}
.socials{
    display: flex;
    gap: 0.5em;
}
.socials a{
    font-size: 25px;
}
.socialslink
{
    color: #86C232;
}
.hello{
    font-size: 2em;
    font-family: var(--mainFont);
}
.name{
    font-size: 4em;
    font-weight: 1000px;
    padding-bottom: 10px;
    font-family: 'Tilt Prism', cursive;
    margin: 0;
}
.developer{
    font-size: 2em;
    font-family: var(--mainFont);
    padding-top: 0%;
    margin-top: 0%;
}
.blob{
    width: 90%;
    font-size: 1.1em;
    font-weight: bold;
    font-family: 'Roboto Slab', serif;
    padding-top: 0%;
    margin-top: 0%;
    text-align: justify;
    line-height: 1.5;
}
.downloadbutton{
    height: 40px;
    width: 120px;
    border-style: none;
    border-radius: 10px;
    color: var(--green);
    font-weight: 300;
    background-color: var(--grey3);
    cursor: pointer;
}

@media only screen and (max-width: 800px) {
    .about{
        background-color: #474B4F;
        padding: 5% 5% 5% 5%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .texts{
        width: 100%;
        padding: 0em 5em 0em 5em;
    }
    .name{
        font-size: 3em;
    }
    .developer{
        font-size: 1em;
    }
    .about img{
        margin-top: 1vh;
    }
    .blob{
        width: 100%;
    }
}

.education{
    background-color: #6B6E70;
    padding: 2em 10vw 2em 10vw;
    justify-content: space-between;
}
.educations{
    padding-top: 0%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
}
.divider {
    height: 8em;
    width: 0.08em;
    background-color: var(--green);
    align-items: center;
}
.content{
    padding: 1.2em;
}
@media only screen and (max-width: 800px) {
    .educations{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-align: center; 
    }
    .divider {
        height: 0.08em;
        width: 15em;
        background-color: var(--green);
        align-items: center;
    }
}
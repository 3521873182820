.hamburger_menu {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 0%;
  margin: 0%;
  margin: 0%;  
} 

.hamburger {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 22px;
  padding: 2px;
  cursor: pointer;
}

.top_bar,
.middle_bar,
.bottom_bar {
  width: 100%;
  height: 3px;
  background-color: var(--green);
  transition: transform 0.5s  ease-in-out;
}
/* .top_bar_antic {
  width: 100%;
  height: 3px;
  background-color: var(--green);
  transform: rotate(-20deg) translate(-4px, 11px);
  transition: transform 0.1s ease-in-out;
} */
.top_bar_closed {
  width: 100%;
  height: 3px;
  background-color: var(--green);
  transform: rotate(228deg) translate(-8px, -8px);
  transition: transform 0.5s  ease-in-out;
}
.middle_bar_closed {
  opacity: 0%;
}
/* .bottom_bar_antic {
  width: 100%;
  height: 3px;
  background-color: var(--green);
  transform: rotate(20deg) translate(-2px, -5px);
  transition: transform 0.1s  ease-in-out;
} */
.bottom_bar_closed {
  width: 100%;
  height: 3px;
  background-color: var(--green);
  transform: rotate(-225deg) translate(-5px, 5px);
  transition: transform 0.5s ease-in-out;
}

.mobile_menu {
  /* position: relative; */
  display: flex;
  justify-content: space-between;
  top: 10px;
  right: 150px;
  width: 150px;
  padding: 20px 0 20px 0;
  font-size: 25px;
  background-color: var(--grey3);
  z-index: 3;
}
ul{
  margin: 0%;
  padding: 0%;
}
.mobile_menu li{
  padding: 2px;
  cursor: pointer;
  color: var(--green);
}
.mobile_menu li:hover{
  color: var(--greenDark);
}
.mobile_menu_closed {
  display: none;
}

.nav_menu
{
    padding: 1em 5em 1em 5em;
    display: flex;
    background-color: var(--grey3);
    align-items: center;
    position: sticky; 
    top: 0;
}
.menu_initials a, a:hover, a:focus, a:active{
    font-weight: 800;
    font-family: "Avenir Next", "Avenir", sans-serif;
    color: var(--green);
    text-decoration:none; 
    cursor:pointer;
}
.menu_full
{
    margin: 0;
    padding: 0;
    width: 100%;
    height: 40px; 
    display :flex;
    align-items:center;
    justify-content:flex-end;
    font-family: "Avenir Next", "Avenir", sans-serif;
    font-weight: lighter;
}
ul
{
    list-style:none;
    gap:10px;
}
.menu_item {
    text-decoration: none;  
    color : #222629;
    display: flex;
    text-align:center;
    cursor: pointer;
    color: var(--green);
}
.menu_item:hover
{
    color:  var(--greenDark);
}
.mobile_menu{
    display: none;
    /* width: 100%; */
    padding: 0%;
    margin: 0%;
}

@media only screen and (max-width: 800px) {
    .nav_menu {
        padding: 1em 1em 1em 1em;
    }
    .menu_full{
        display :flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content:flex-end;
    }
    .mobile_menu{
        display: flex;
        /* display: inline; */
        color: #222629;
    }
    .menu_item{
        display: none;
    } 
    .menu_full{
        flex-direction: column;
        height: 100%;
    }
}
.Works{
    background-color: #474B4F;
    padding: 2% 10% 2% 10%;
}
.grid_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.grid_item {
    padding: 1em;
    margin: 0%;
    text-align: center;
    width: 280px;
} 
.grid_item iframe{
    width: 100%;
}
.button{
    background: none;
}
img{
    width: 100%;
}
.description{
    font-weight: 200px;
}
.worksallbuttons{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.worksbutton{
    height: 40px;
    width: 120px;
    border-style: none;
    border-radius: 5px;
    font-weight: 300;
    cursor: pointer;
    margin: 10px;
    color: var(--green);
    background-color: var(--grey3);
}
.worksbuttonOn{
    height: 40px;
    width: 120px;
    border-style: none;
    border-radius: 5px;
    font-weight: 300;
    cursor: pointer;
    margin: 10px;
    color: var(--grey3);
    background-color: var(--green);
}
.worksbutton:hover, .worksbuttonOn:hover{
    font-weight:bold;
}
@media only screen and (max-width: 800px) {
    .Works{
        background-color: #474B4F;
        padding: 2% 5% 2% 5%;
    }
    .grid_container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
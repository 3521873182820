:root{
    --green: #86C232;
    --greenDark: #61892F;
    --grey1:  #6B6E70;
    --grey2:  #474B4F;
    --grey3:  #222629;
    --mainFont: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.body{
    font-family: var(--mainFont);
}
.full_page{
    display: flex;
    flex-direction: column;
}
.section_name{
    width:100%;
    text-align: center;
    color: var(--green);
    margin: 0%;
    padding: 0%;
    font-family: var(--mainFont);
    
}
.Banner
{
    background-color: #6B6E70;
    text-align: center;
    height: 500px;
}
footer {
    background-color: var(--grey3);
    color: #fff;
    padding: 0 2em 0 2em;
}
.container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content:space-between;
}

@media only screen and (max-width: 800px) {
    .container {
        flex-direction: column;
        text-align: center;
        padding: 0;
        margin: 0%;
    }
    .container p{
        padding: 0.2em 0 0.2em 0;
        margin: 0%;
    }
}